import { ContentFaceCard } from '../../components/ContentFaceCard'
import { Box, Grid, Typography, Button, useTheme, Theme } from '@mui/material';
import aboutBg from '../../assets/backgrounds/about-me-bg.jpg';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import NpmIcon from '../../components/icons/NpmIcon';

const makeStyles = (theme: Theme) => {
  return ({
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: "100%",
    },
    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: "100%",
    },
    pageTitleContainer: {
      borderRight: `1px solid ${theme.palette.text.secondary}`, height: '100%', flex: 1, position: 'relative'
    },
    pageTitle: {
      transform: "rotate(-90deg)",
      width: "562%",
      marginLeft: 0,
      textAlign: "center",
      left: "-236%",
      position: "absolute",
      top: "47%",
      fontSize: "30px",
      lineHeight: "40px",
      fontWeight: 800,
    },
    cardContainer: {
      minHeight: '85vh', minWidth: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    leftColumnContents: { minHeight: '85vh', width: "100%" }
  })
}

const getStyledRow = (field: string, value: string) => {
  return (
    <tr>
      <td><Typography variant="subtitle1">{field}</Typography></td>
      <td>:</td>
      <td><Typography variant="subtitle1"><b>&nbsp;&nbsp;{value}</b></Typography></td>
    </tr>
  )
}


export const About = () => {
  const title = "about me"
  const theme = useTheme();
  const classes = makeStyles(theme);

  return (
    <ContentFaceCard>
      <Grid container sx={classes.cardContainer}>
        <Grid item md={6} xs={12} sx={classes.leftColumn}>
          <Grid container direction="row" justifyContent="center" alignItems="center" sx={classes.leftColumnContents}>
            <Grid item md={2} sx={classes.pageTitleContainer}>
              <Typography sx={classes.pageTitle}>
                <span style={{ color: theme.palette.primary.main }}> _ </span>
                {title}
                <span style={{ color: theme.palette.primary.main }}> _ </span>
              </Typography>
            </Grid>
            <Grid item md={10} xs={12} sx={{
              backgroundColor: "#555", minHeight: "100%",
              backgroundImage: `url(${aboutBg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
              <Box sx={{ padding: 10 }}>
                <table style={{ marginTop: 200 }}>
                  <tbody>
                    {getStyledRow("Name", "Akhil Krishnan R")}
                    {getStyledRow("Nationality", "India")}
                    {getStyledRow("Date of birth", "24 February 1994")}
                    {getStyledRow("Phone", "+91 7907051211")}
                    {getStyledRow("Email", "akhilkrishnan.official@gmail.com")}
                    {getStyledRow("Experience", `${new Date().getFullYear() - 2017} + Years`)}
                  </tbody>
                </table>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<SaveAltIcon fontSize='small' />}
                  sx={{ marginTop: 10 }}
                >
                  Download Resume
                </Button>
              </Box>
              <Box sx={{ paddingLeft: 10 }}>
                <IconButton>
                  <LinkedInIcon />
                </IconButton>&nbsp;&nbsp;
                <IconButton>
                  <FacebookIcon />
                </IconButton>&nbsp;&nbsp;
                <IconButton>
                  <InstagramIcon />
                </IconButton>&nbsp;&nbsp;
                <IconButton>
                  <GitHubIcon />
                </IconButton>&nbsp;&nbsp;
                <IconButton>
                  <NpmIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} sx={classes.rightColumn}>
          <Grid item md={12} xs={12} sx={{
            minHeight: "100%", width: "100%",
          }}>
            <Box sx={{ width: "100%", height: 200, backgroundColor: "#FEBE34" }}>
              {/* <Box sx={{ width: "100%", height: 200, backgroundColor: theme.palette.secondary.light }}> */}
              <Typography variant="h2" sx={{ paddingLeft: 9, paddingTop: 17.8, fontWeight: 800, color: 'white' }}>short history</Typography>
            </Box>
            <Box sx={{ padding: 10 }}>
              <Typography variant="h4" sx={{ paddingTop: 0, fontWeight: 1000 }}>Hi</Typography>
              <Typography variant="h5" sx={{ paddingTop: 2, textAlign: 'justify' }}>I am a fullstack Node.js developer from Kerala, India and hold a Bachelor of Technology degree in Computer Science & Engineering from Kerala University</Typography>
              <br />
              <Typography variant='body2' sx={{ textAlign: 'justify' }} color="textSecondary">
                As a fullstack Node.js developer, I have a deep understanding of the JavaScript ecosystem and the tools and frameworks it has to offer. I am skilled in developing server-side logic and integrations, as well as creating user-friendly front-end interfaces using modern web technologies.
              </Typography>
              <br />
              <Typography variant='body2' sx={{ textAlign: 'justify' }} color="textSecondary">
                As a cloud solutions developer, I have extensive experience working with various cloud platforms, including Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform. I am skilled in designing and implementing scalable and highly available cloud-based solutions, as well as configuring and managing cloud infrastructure.
              </Typography>
              <br />
              <Typography variant='body2' sx={{ textAlign: 'justify' }} color="textSecondary">
                My goal is to work closely with my clients to understand their needs and provide solutions that are tailored to their specific requirements. I believe in building long-term relationships with my clients and strive to provide excellent customer service and support.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ContentFaceCard>
  )
}
