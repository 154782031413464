import { ContentFaceCard } from '../components/ContentFaceCard'
import { Box, Grid, Typography, useTheme, Theme, Button } from '@mui/material';
import careerBg from '../assets/backgrounds/career-bg.jpg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const makeStyles = (theme: Theme) => {
    return ({
        leftColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: "100%",
        },
        rightColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
        },
        pageTitleContainer: {
            borderRight: `1px solid ${theme.palette.text.secondary}`, height: '100%', flex: 1, position: 'relative'
        },
        pageTitle: {
            transform: "rotate(-90deg)",
            width: "562%",
            marginLeft: 0,
            textAlign: "center",
            left: "-236%",
            position: "absolute",
            top: "47%",
            fontSize: "30px",
            lineHeight: "40px",
            fontWeight: 800,
        },
        cardContainer: {
            minHeight: '85vh', minWidth: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        leftColumnContents: { minHeight: '90vh', width: "100%" }
    })
}

export const Career = () => {
    const title = "career"
    const theme = useTheme();
    const classes = makeStyles(theme);

    return (
        <ContentFaceCard>
            <Grid container sx={classes.cardContainer}>
                <Grid item md={6} xs={12} sx={classes.leftColumn}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={classes.leftColumnContents}>
                        <Grid item md={2} sx={classes.pageTitleContainer}>
                            <Typography sx={classes.pageTitle}>
                                <span style={{ color: theme.palette.primary.main }}> _ </span>
                                {title}
                                <span style={{ color: theme.palette.primary.main }}> _ </span>
                            </Typography>
                        </Grid>
                        <Grid item md={10} sx={{ minHeight: "100%" }}>
                            <Grid container sx={{ minHeight: "100%" }}>
                                <Grid item md={6} xs={12} sx={{ minHeight: "86vh" }}>
                                    <Typography variant="h5">
                                        <FormatQuoteIcon
                                            sx={{ fontSize: 100, transform: 'rotate(-180deg)', color: theme.palette.primary.main, marginTop: "55%" }} />
                                    </Typography>
                                    <Box>
                                        <Typography variant="subtitle2" sx={{ textAlign: 'justify', padding: 6.5, backgroundColor: theme.palette.grey[200] }}>
                                            The only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle. As with all matters of the heart, you'll know when you find it.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12} sx={{
                                    backgroundColor: "#555", minHeight: "100%",
                                    backgroundImage: `url(${careerBg})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    width: "100%",
                                }}>
                                    <Box sx={{}}>

                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12} sx={classes.rightColumn}>
                    <Grid container>
                        <Grid item md={12} xs={12} sx={{ minHeight: "100%", maxHeight: "70vh", overflowY: 'scroll' }}>
                            <Box sx={{ padding: 10 }}>
                                <Grid container sx={{ padding: 2 }}>
                                    <Grid item md={4}><Button variant='contained'>2020 → Now</Button></Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body2" color='primary'>
                                            <b>Senior Software Engineer</b>
                                        </Typography>
                                        <Typography variant="h5">
                                            Techversant Infotech
                                        </Typography>
                                        <Typography variant="body2">
                                            Third floor, Yamuna, Tower II,Technopark Phase III
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container sx={{ padding: 2 }}>
                                    <Grid item md={4}><Button variant='contained'>2018 → 2020</Button></Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body2" color='primary'>
                                            <b>Software Developer</b>
                                        </Typography>
                                        <Typography variant="h5">
                                            Mozanta Technologies
                                        </Typography>
                                        <Typography variant="body2">
                                            Thejaswini BuildingTechnopark, Trivandrum
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container sx={{ padding: 2 }}>
                                    <Grid item md={4}><Button variant='contained'>2018 → 2018</Button></Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body2" color='primary'>
                                            <b>Software Developer & System Administrator</b>
                                        </Typography>
                                        <Typography variant="h5">
                                            RPTC Centre for Language Studies
                                        </Typography>
                                        <Typography variant="body2">
                                            Anchal, Kollam
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container sx={{ padding: 2 }}>
                                    <Grid item md={4}><Button variant='contained'>2017 → 2018</Button></Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body2" color='primary'>
                                            <b>Trainee Developer & Administrative Staff</b>
                                        </Typography>
                                        <Typography variant="h5">
                                            Glozways Private Limited
                                        </Typography>
                                        <Typography variant="body2">
                                            Ezhukon, Kollam
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentFaceCard>
    )
}