import React, { ReactNode } from 'react';
import { Box, useTheme, Theme } from '@mui/material';
import Paper from '@mui/material/Paper';

const makeStyles = (theme: Theme) => {
  return ({
    container: {
      width: "100%",
      height: '98vh',
      backgroundColor: 'e5e5e5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    childBox: {
      width: '100%',
      height: '90%',
      backgroundColor: 'lightgrey',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
}

interface IContentFaceCardProps { children: ReactNode };

export const ContentFaceCard: React.FC<IContentFaceCardProps> = ({ children }) => {
  const theme = useTheme();
  const classes = makeStyles(theme);

  return (
    <Box sx={{ ...classes.container }}>
      <Box sx={classes.childBox}>
        <Paper elevation={1} sx={{ minHeight: '100%', minWidth: '100%' }}>
          {children}
        </Paper>
      </Box>
    </Box >
  )
}
