import { ContentFaceCard } from '../../components/ContentFaceCard'
import { SkillsLanding } from './components/SkillsLanding';
import { useState } from 'react';
import { SkillsDetails } from './components/SkillsDetails';

export const Skills = () => {
    const [activeComponent, setActiveComponent] = useState("landing")
    const handleChangeComponet = (id: string) => {
        setActiveComponent(id)
    }
    return (
        <ContentFaceCard>
            {activeComponent === 'landing' ? (
                <SkillsLanding handleChangeComponet={handleChangeComponet} />
            ) : (
                <SkillsDetails handleChangeComponet={handleChangeComponet} />
            )}
        </ContentFaceCard>
    )
}