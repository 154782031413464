import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Theme, useTheme } from '@mui/material'
import { EPageIds, EPageLabels } from '../data/enums/pageMetadata';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface Props {
    navIcon: JSX.Element;
    selected: boolean;
    id: EPageIds;
    label: EPageLabels;
    handlePageChange: (id: EPageIds) => void,
}

type makeStyleProps = {
    theme: Theme;
    selected: boolean;
}

const makeStyles = (attr: makeStyleProps) => {
    const { theme, selected } = attr;
    return ({
        button: {
            borderWidth: 2,
            borderStyle: 'solid',
            backgroundColor: selected ? theme.palette.primary.main : 'transparent',
            color: selected ? theme.palette.primary.contrastText : theme.palette.text.secondary,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: 'white',
            },
            '&:active': {
                backgroundColor: theme.palette.text.secondary,
                color: 'white',
            }
        }
    })
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export const PageNavButton: React.FC<Props> = ({ navIcon: NavIcon, selected, handlePageChange, id, label }) => {
    const theme = useTheme()
    const classes = makeStyles({ theme, selected });
    return (
        <StyledTooltip title={label} arrow placement="right" sx={{ color: theme.palette.primary.main }}>
            <IconButton
                sx={classes.button}
                color={selected ? 'primary' : 'default'}
                size='small'
                onClick={() => handlePageChange(id)}
            >
                {NavIcon}
            </IconButton>
        </StyledTooltip>
    )
}
