import { SvgIcon, SvgIconProps } from "@mui/material";

function NpmIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 1024 1024" ><path d="M768 128H256a128 128 0 0 0-128 128v512a128 128 0 0 0 128 128h298.666667V469.333333h170.666666v426.666667h42.666667a128 128 0 0 0 128-128V256a128 128 0 0 0-128-128z"  /></svg>
        </SvgIcon>
    );
}

export default NpmIcon