import { ContentFaceCard } from '../components/ContentFaceCard'
import { Box, Grid, Typography, useTheme, Theme, Button } from '@mui/material';
import educationBg from '../assets/backgrounds/education-bg.jpg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const makeStyles = (theme: Theme) => {
    return ({
        leftColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: "100%",
        },
        rightColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
        },
        pageTitleContainer: {
            borderRight: `1px solid ${theme.palette.text.secondary}`, height: '100%', flex: 1, position: 'relative'
        },
        pageTitle: {
            transform: "rotate(-90deg)",
            width: "562%",
            marginLeft: 0,
            textAlign: "center",
            left: "-236%",
            position: "absolute",
            top: "47%",
            fontSize: "30px",
            lineHeight: "40px",
            fontWeight: 800,
        },
        cardContainer: {
            minHeight: '85vh', minWidth: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        leftColumnContents: { minHeight: '90vh', width: "100%" }
    })
}

export const Education = () => {
    const title = "education"
    const theme = useTheme();
    const classes = makeStyles(theme);

    return (
        <ContentFaceCard>
            <Grid container sx={classes.cardContainer}>
                <Grid item md={7} xs={12} sx={classes.leftColumn}>
                    <Grid container sx={{ minHeight: "100%" }}>
                        <Grid item md={2} sx={classes.pageTitleContainer}>
                            <Typography sx={classes.pageTitle}>
                                <span style={{ color: theme.palette.primary.main }}> _ </span>
                                {title}
                                <span style={{ color: theme.palette.primary.main }}> _ </span>
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12} sx={{ minHeight: "100%", }}>
                            <Box>
                                <Typography variant="h6" sx={{ textAlign: 'justify', padding: 10 }} color='textSecondary'>
                                    Education is not the learning of facts, but the training of the mind to think. Education is a gift that none can take away. I am still learning every day.
                                </Typography>
                            </Box>
                            <Box sx={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10, maxHeight: "70vh", overflowY: 'scroll' }}>
                                <Grid container sx={{ padding: 2 }}>
                                    <Grid item md={3}><Typography variant='h2' color='primary'>01.</Typography></Grid>
                                    <Grid item md={9}>
                                        <Typography variant="h5" >
                                            B.Tech in Computer Science
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            <b>UKF College of Engineering & Technology</b>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Parippally, Kollam, Kerala
                                        </Typography>
                                        <Typography variant="body2" color='primary'>
                                            2013 → 2017
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container sx={{ padding: 2 }}>
                                    <Grid item md={3}><Typography variant='h2' color='primary'>02.</Typography></Grid>
                                    <Grid item md={9}>
                                        <Typography variant="h5" >
                                            Schooling
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            <b>ST. John's Central School</b>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Anchal, Kollam, Kerala
                                        </Typography>
                                        <Typography variant="body2" color='primary'>
                                            2000 → 2012
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={5} xs={12} sx={classes.rightColumn}>
                    <Grid item md={12} xs={12} sx={{
                        backgroundColor: "#555", minHeight: "100%",
                        backgroundImage: `url(${educationBg})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        content: '""',
                        width: "100%",
                        position: 'relative'
                    }}>
                        <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                            <Typography variant="h5"
                                sx={{ textAlign: 'right', padding: 7, paddingTop: 4, backgroundColor: 'rgba(255,180,0,0.8)', color: theme.palette.common.white }}>
                                <FormatQuoteIcon
                                    sx={{ fontSize: 100, transform: 'rotate(-180deg)', color: theme.palette.common.white }} />
                                <b>Education is not Preparation For Life, <br /> Education is Life Itself..</b>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </ContentFaceCard>
    )
}