import React from 'react'
import { PageNavButton } from '../../components/PageNavButton';
import { Box } from '@mui/material';
import { IAppPageMetadata } from '../../data/interfaces/pageMetadata';
import { EPageIds } from '../../data/enums/pageMetadata';


export interface IPageNavigationProps {
  pages: IAppPageMetadata[],
  selectedPage: EPageIds,
  handlePageChange: (id: EPageIds) => void;
}

const PageNavigation: React.FC<IPageNavigationProps> = ({ pages, selectedPage, handlePageChange }) => {
  return (
    <Box sx={{ top: "50%" }}>
      {pages.map((page, index) => (
        <Box key={page.id}>
          <PageNavButton
            navIcon={page.navIcon}
            id={page.id}
            label={page.label}
            handlePageChange={handlePageChange}
            selected={page.id === selectedPage}
          />
          {index < (pages.length - 1) && (<><div>|</div><div>|</div></>)}
        </Box>
      ))}
    </Box>
  )
}

export default PageNavigation