import { Box, Grid, Typography, Button, useTheme, Theme } from '@mui/material';
import skillsBg from '../../../assets/backgrounds/skills-bg.jpg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useState } from "react";

const makeStyles = (theme: Theme) => {
    return ({
        leftColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: "100%",
        },
        rightColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
        },
        pageTitleContainer: {
            borderRight: `1px solid ${theme.palette.text.secondary}`, height: '100%', flex: 1, position: 'relative'
        },
        pageTitle: {
            transform: "rotate(-90deg)",
            width: "562%",
            marginLeft: 0,
            textAlign: "center",
            left: "-236%",
            position: "absolute",
            top: "47%",
            fontSize: "30px",
            lineHeight: "40px",
            fontWeight: 800,
        },
        cardContainer: {
            minHeight: '85vh', minWidth: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transition: "opacity 0.5s ease-in-out"
        },
        leftColumnContents: { minHeight: '90vh', width: "100%" }
    })
}

export interface ISkillsLandingProps {
    handleChangeComponet: (id: string) => void;
}

export const SkillsLanding: React.FC<ISkillsLandingProps> = ({ handleChangeComponet }) => {
    const title = "skills"
    const theme = useTheme();
    const classes = makeStyles(theme);
    return (
        <>
            <Grid container sx={classes.cardContainer}>
                <Grid item md={7} xs={12} sx={classes.leftColumn}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={classes.leftColumnContents}>
                        <Grid item md={2} sx={classes.pageTitleContainer}>
                            <Typography sx={classes.pageTitle}>
                                <span style={{ color: theme.palette.primary.main }}> _ </span>
                                {title}
                                <span style={{ color: theme.palette.primary.main }}> _ </span>
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12} sx={{ minHeight: "100%" }}>
                            <Box sx={{ padding: 10 }}>
                                <Typography variant="h5"><FormatQuoteIcon sx={{ fontSize: 80, transform: 'rotate(-180deg)', color: theme.palette.primary.main }} /></Typography>
                                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                                    Well-designed systems not only delight users, but also generate profit for manufacturers and satisfy the most discerning critics
                                </Typography>
                                <Typography variant="body2" sx={{ marginTop: 5, textAlign: 'justify' }}>
                                    As a fullstack cloud developer, I am responsible for designing and implementing scalable and highly available cloud-based solutions, as well as configuring and managing cloud infrastructure. I ensure that mock-ups meet both visual and textual branding standards, and design adaptive layouts t    hat maintain a consistent user experience across the product. I also create clickable prototypes when needed and possess a creative mind and strong attention to detail. I work closely with clients to understand their needs and provide solutions tailored to their specific requirements, while building long-term relationships and providing excellent customer service and support.
                                </Typography>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    endIcon={<ArrowRightAltIcon />}
                                    sx={{ marginTop: 10 }}
                                    onClick={() => handleChangeComponet('details')}
                                >
                                    View My Skills
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={5} xs={12} sx={classes.rightColumn}>
                    <Grid item md={12} xs={12} sx={{
                        backgroundColor: "#555", minHeight: "100%",
                        backgroundImage: `url(${skillsBg})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        content: '""',
                        width: "100%"
                    }}>
                        <Box sx={{}}>

                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}