import { Box, Grid, ThemeProvider } from '@mui/material'
import { createTheme, useMediaQuery } from '@mui/material';
import React, { useRef, useState, useEffect, useCallback } from 'react'
import Landing from './pages/Landing'
import PageNavigation from './components/PageNavigation';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SchoolIcon from '@mui/icons-material/School';
import CollectionsIcon from '@mui/icons-material/Collections';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { IAppPageMetadata } from './data/interfaces/pageMetadata';
import { EPageIds, EPageLabels } from './data/enums/pageMetadata';
import { About } from './pages/About';
import { Skills } from './pages/Skills';
import { Career } from './pages/Career';
import { Education } from './pages/Education';
import { Contact } from './pages/Contact';
import { PageNavButton } from './components/PageNavButton';

const pages: IAppPageMetadata[] = [
  {
    id: EPageIds.LANDING,
    label: EPageLabels.LANDING,
    navIcon: <LocalFireDepartmentIcon fontSize='small' />,
  },
  {
    id: EPageIds.ABOUT,
    label: EPageLabels.ABOUT,
    navIcon: <FingerprintIcon fontSize='small' />,
  },
  {
    id: EPageIds.SKILLS,
    label: EPageLabels.SKILLS,
    navIcon: <ColorLensIcon fontSize='small' />,
  },
  {
    id: EPageIds.CAREER,
    label: EPageLabels.CAREER,
    navIcon: <WorkHistoryIcon fontSize='small' />,
  },
  {
    id: EPageIds.EDUCATION,
    label: EPageLabels.EDUCATION,
    navIcon: <SchoolIcon fontSize='small' />,
  },
  {
    id: EPageIds.PORTFOLIO,
    label: EPageLabels.PORTFOLIO,
    navIcon: <CollectionsIcon fontSize='small' />,
  },
  {
    id: EPageIds.CONTACT,
    label: EPageLabels.CONTACT,
    navIcon: <TouchAppIcon fontSize='small' />,
  },
]

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: {
        main: "#ED1F28" //1DBD64 - dark
      },
      secondary: {
        main: "#021639"
      },
      success: {
        main: "#0CF631"
      },
      error: {
        main: "#F60C0C"
      },
      text: {
        primary: "#191E3E",
        secondary: "#999999"
      },
    },
  });
  const [thisSelectedPage, setSelectedPage] = useState(EPageIds.LANDING);
  const isMobile = useMediaQuery('(max-width:600px)');

  const aboutRef = useRef();
  const landingRef = useRef();
  const skillsRef = useRef();
  const careerRef = useRef();
  const educationRef = useRef();
  const contactRef = useRef();

  const bringToView = (id: EPageIds) => {
    if (landingRef?.current) {
      landingRef.current.style.opacity = 0;
    }
    if (aboutRef?.current) {
      aboutRef.current.style.opacity = 0;
    }
    if (skillsRef?.current) {
      skillsRef.current.style.opacity = 0;
    }
    if (careerRef?.current) {
      careerRef.current.style.opacity = 0;
    }
    if (educationRef?.current) {
      educationRef.current.style.opacity = 0;
    }
    if (contactRef?.current) {
      contactRef.current.style.opacity = 0;
    }
    switch (id) {
      case EPageIds.LANDING: {
        if (landingRef?.current) {
          landingRef.current.style.opacity = 1;
        }
        break;
      }
      case EPageIds.ABOUT: {
        if (aboutRef?.current) {
          aboutRef.current.style.opacity = 1;
        }
        break;
      }
      case EPageIds.SKILLS: {
        if (skillsRef?.current) {
          skillsRef.current.style.opacity = 1;
        }
        break;
      }
      case EPageIds.CAREER: {
        if (careerRef?.current) {
          careerRef.current.style.opacity = 1;
        }
        break;
      }
      case EPageIds.EDUCATION: {
        if (educationRef?.current) {
          educationRef.current.style.opacity = 1;
        }
        break;
      }
      case EPageIds.CONTACT: {
        if (contactRef?.current) {
          contactRef.current.style.opacity = 1;
        }
        break;
      }
      default: {
        if (landingRef?.current) {
          landingRef.current.style.opacity = 1;
        }
        break;
      }
    }
  }

  const handlePageChange = (id: EPageIds) => {
    setSelectedPage(id);
    console.count(`handlePageChange:${id}`);
    bringToView(id)
    switch (id) {
      case EPageIds.LANDING: return landingRef?.current?.scrollIntoView({ behavior: 'smooth' })
      case EPageIds.ABOUT: return aboutRef?.current?.scrollIntoView({ behavior: 'smooth' })
      case EPageIds.SKILLS: return skillsRef?.current?.scrollIntoView({ behavior: 'smooth' })
      case EPageIds.CAREER: return careerRef?.current?.scrollIntoView({ behavior: 'smooth' })
      case EPageIds.EDUCATION: return educationRef?.current?.scrollIntoView({ behavior: 'smooth' })
      case EPageIds.CONTACT: return contactRef?.current?.scrollIntoView({ behavior: 'smooth' })
      default: return landingRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  console.log({
    aboutRef,
    landingRef,
    skillsRef,
    careerRef,
    educationRef,
    contactRef
  });

  useEffect(() => {
    if (aboutRef?.current) {
      aboutRef.current.style.opacity = 0;
    }
    if (skillsRef?.current) {
      skillsRef.current.style.opacity = 0;
    }
    if (careerRef?.current) {
      careerRef.current.style.opacity = 0;
    }
    if (educationRef?.current) {
      educationRef.current.style.opacity = 0;
    }
    if (contactRef?.current) {
      contactRef.current.style.opacity = 0;
    }

    return () => {

    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="row" >
        <Grid item lg={0.5} sx={{
          backgroundColor: "#e5e5e5",
          overflow: 'hidden',
        }}>
          {
            isMobile ?
              (
                <Box sx={{ display: "flex", position: 'absolute', bottom: 2, minWidth: '98%', alignItems: "center",
                justifyContent: "center", }}>
                  {pages.map((page, index) => (
                    <Box key={page.id}>
                      <PageNavButton
                        navIcon={page.navIcon}
                        id={page.id}
                        label={page.label}
                        handlePageChange={handlePageChange}
                        selected={page.id === thisSelectedPage}
                      />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item md={12} sx={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "98vh"
                  }}>
                    <PageNavigation pages={pages} selectedPage={thisSelectedPage} handlePageChange={handlePageChange} />
                  </Grid>
                </Grid>
              )}
        </Grid>
        <Grid item lg={11.5} xs={12} sx={{ overflow: 'hidden', maxHeight: '90vh', alignItems: "center",
                    justifyContent: "center" }}>
          <Box ref={landingRef} className={`fade`}>
            <Landing />
          </Box>
          <Box ref={aboutRef} className={`fade`}>
            <About />
          </Box>
          <Box ref={skillsRef} className={`fade`}>
            <Skills />
          </Box>
          <Box ref={careerRef} className={`fade`}>
            <Career />
          </Box>
          <Box ref={educationRef} className={`fade`}>
            <Education />
          </Box>
          <Box ref={contactRef} className={`fade`}>
            <Contact />
          </Box>
        </Grid>
      </Grid >
    </ThemeProvider >
  )
}

export default App
