export enum EPageIds {
    LANDING = 'home',
    ABOUT = 'about',
    SKILLS = 'skills',
    CAREER = 'career',
    EDUCATION = 'education',
    PORTFOLIO = 'portfolio',
    CONTACT = 'contact',
}

export enum EPageLabels {
    LANDING = 'home',
    ABOUT = 'about',
    SKILLS = 'skills',
    CAREER = 'career',
    EDUCATION = 'education',
    PORTFOLIO = 'portfolio',
    CONTACT = 'contact',
}
