import { ContentFaceCard } from '../components/ContentFaceCard'
import { Box, Grid, Typography, useTheme, Theme, Button } from '@mui/material';
import careerBg from '../assets/backgrounds/education-bg.jpg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Paper from '@mui/material/Paper';

const makeStyles = (theme: Theme) => {
    return ({
        leftColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: "100%",
        },
        rightColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
        },
        pageTitleContainer: {
            borderRight: `1px solid ${theme.palette.text.secondary}`, height: '100%', flex: 1, position: 'relative'
        },
        pageTitle: {
            transform: "rotate(-90deg)",
            width: "562%",
            marginLeft: 0,
            textAlign: "center",
            left: "-236%",
            position: "absolute",
            top: "47%",
            fontSize: "30px",
            lineHeight: "40px",
            fontWeight: 800,
        },
        cardContainer: {
            minHeight: '85vh', minWidth: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        leftColumnContents: { minHeight: '90vh', width: "100%" }
    })
}

export const Contact = () => {
    const title = "contact"
    const theme = useTheme();
    const classes = makeStyles(theme);

    return (
        <ContentFaceCard>
            <Grid container sx={classes.cardContainer}>
                <Grid item md={6} xs={12} sx={classes.leftColumn}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={classes.leftColumnContents}>
                        <Grid item md={2} sx={classes.pageTitleContainer}>
                            <Typography sx={classes.pageTitle}>
                                <span style={{ color: theme.palette.primary.main }}> _ </span>
                                {title}
                                <span style={{ color: theme.palette.primary.main }}> _ </span>
                            </Typography>
                        </Grid>
                        <Grid item md={10} sx={{ minHeight: "100%" }}>
                            <Grid container sx={{ minHeight: "100%" }}>
                                <Grid item md={12} xs={12} sx={{
                                    backgroundColor: "#555", minHeight: "100%",
                                    backgroundImage: `url(${careerBg})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    width: "100%",
                                    height: '86vh'
                                }}>
                                    <Box sx={{}}>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12} sx={classes.rightColumn}>
                    <Grid container>
                        <Grid item md={12} xs={12} sx={{ minHeight: "100%", maxHeight: "70vh", overflowY: 'scroll' }}>
                            <Box sx={{ padding: 10 }}>
                                <Grid container direction="row" spacing={2} >
                                    <Grid item md={4} sx={{ backgroundColor: theme.palette.text.secondary, borderRadius: 1 }}>
                                        hello
                                    </Grid>
                                    <Grid item md={4} sx={{ backgroundColor: theme.palette.text.secondary, borderRadius: 1 }}>
                                        hello
                                    </Grid>
                                    <Grid item md={4} sx={{ backgroundColor: theme.palette.text.secondary, borderRadius: 1 }}>
                                        hello
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentFaceCard>
    )
}