import React from 'react';
import { Box, Grid, Typography, Button, useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import slider1 from '../assets/backgrounds/home-slider-1.jpg'
import { ContentFaceCard } from '../components/ContentFaceCard';

const makeStyles = () => {
    return ({
        container: {
            width: "91vw",
            height: '98vh',
            backgroundColor: 'e5e5e5',
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'center',
            // backgroundColor: '#f00',
        },
        childBox: {
            width: '100%',
            height: '90%',
            backgroundColor: 'lightgrey',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        leftColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        rightColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
}

const Landing: React.FC = () => {
    const classes = makeStyles();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <ContentFaceCard>
            {isMobile ?
                (
                    <Grid container sx={{
                        minHeight: '85vh', minWidth: '100%', backgroundImage: `url(${slider1})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}>

                    </Grid>
                ) : (
                    <Grid container sx={{
                        minHeight: '85vh', minWidth: '100%', backgroundImage: `url(${slider1})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}>
                        <Grid item md={7} xs={12} sx={classes.leftColumn}>
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sx={{ padding: 2, minHeight: '90vh' }}>
                                <Grid item md={8} xs={12} sx={{ marginTop: '5%' }}>
                                    <Typography variant="h3"><b>Hey There!</b></Typography>
                                    <Typography variant="h3"><b>I'm Akhil Krishnan</b></Typography>
                                    <br />
                                    <br />
                                    <Typography variant="body2">
                                        I am an experienced fullstack Node.js developer and cloud solutions developer, dedicated to delivering high-quality web applications and cloud-based systems that meet the needs of my clients.
                                    </Typography>
                                    <br />
                                    <br />
                                    <Button variant="contained" color="primary">
                                        Get in Touch
                                    </Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button variant="outlined" color="primary">
                                        About Me
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={5} xs={12} sx={classes.rightColumn}>
                        </Grid>
                    </Grid>
                )}
        </ContentFaceCard>
    );
};

export default Landing;
